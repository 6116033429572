const styles = (theme) => ({

  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: 826,
    [theme.breakpoints.down("sm")]: {
      height: 726,
    },
    [theme.breakpoints.down("xs")]: {
      height: 626,
    },
  },
  fourOFour: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    "& .fourOFourImg": {
      verticalAlign: "bottom"
    },
  },
  backgroundOverlay: {
    backgroundColor: "rgba(0,0,0,0.2)",
    height: 826,
    position: "absolute",
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(25, 2, 15),
      height: 726,
    },
    [theme.breakpoints.down("xs")]: {
      height: 626,
    },
  },
  heroTitle: {
    backgroundImage: `url('/homepage-title-background.png')`,
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "fit-content",
    padding: "33px 32px 26px 34px",
    color: "#fff",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: "-10px",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      padding: theme.spacing(2),
    },
  },
  heroSubTitle: {
    backgroundImage: `url('/homepage-subtitle-background.png')`,
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "fit-content",
    padding: "20px 31px",
    color: "#fff",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      padding: theme.spacing(2),
    },
  },
  heroButton: {
    display: "flex",
    marginTop: theme.spacing(4),
    "& button": {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column;",
      alignItems: "center",
    },
  },
});

export default styles;
